import { LocationOn, Phone } from '@material-ui/icons'
import React from 'react'
import styled from 'styled-components'
import InstagramIcon from 'components/Icons/Instagram'

const Card = styled.div({
  padding: '8px',
  color: 'black',
  width: 'min-content',
  '& > *': {
    wordBreak: 'keep-all',
    marginBottom: '24px',
  },
  '& > h3': {
    marginBottom: '40px',
    whiteSpace: 'nowrap',
  },
  '& > p': {
    fontSize: '18px',
  },
})

const Span = styled('a')({
  display: 'flex',
})

const Icon = styled('span')({
  color: ({ theme }) => theme.colors.gray.color,
  marginRight: '8px',
})

export default function StoryblokDealerInfo({ data }) {
  const { companyName, address, phone, instagram } = data
  return (
    <Card>
      {companyName && <h3>{companyName}</h3>}
      {address && (
        <Span>
          <Icon>
            <LocationOn color="inherit" />
          </Icon>
          <p>{address}</p>
        </Span>
      )}
      {phone && (
        <Span href={`tel:${phone}`} target="_blank" rel="noreferrer">
          <Icon>
            <Phone color="inherit" />
          </Icon>
          <p>{phone}</p>
        </Span>
      )}
      {instagram && (
        <Span
          href={`https://www.instagram.com/${instagram?.replace('@', '')}`}
          target="_blank"
          rel="noreferrer"
        >
          <Icon>
            <InstagramIcon color="inherit" />
          </Icon>
          <p>{instagram}</p>
        </Span>
      )}
    </Card>
  )
}
